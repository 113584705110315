import React from "react"

const IgonSyurui = () => {
  const title = `遺言の作成について`
  const content = `遺言書という言葉やその意味をご存知の方は多いと思いますが、法律でその作成方法が決まっているのをご存知でしょうか？（民法９６０条・９６７条以下）法律で定められた形式に合致していないものは無効となります。
遺言の効力が発生するのはその方自身が亡くなった時になります。よって、当然のことながらその時には本人は既にいない訳で、紛争の未然防止の観点からその方の遺志を所定の方式の書面（証書）で明確にしておく必要が出てきます。
その中でも代表的なものは、①自筆証書遺言、②公正証書遺言、③秘密証書遺言の３つです。
遺言書は財産の多寡に関わらず、遺された親族間での不要な争いを避けることができるだけでなく、相続手続きをスムーズに行えるようになります。
そして、専門家や、信頼のおける相続人の一人、友人・知人を遺言執行者に指定することによって、遺言内容の実現を任せることができます。
遺言書の作成について、「自分には関係ない、まだ早い」と思っていらっしゃる方も多いかと思いますが、
実はそんなことはありません。以下の例に思い当たる方は、是非一度公正証書遺言の作成をご検討下さい。
なお、あまり費用をかけずに済ませたいという方は、自筆証書遺言の法務局保管制度を使えば、公正証書作成費用よりは安価に遺言書を作成・保管することも可能です。
また、最近では、遺言に生前対策を盛り込むような構成にもできる「家族信託」と言われる仕組みも利用されることが増えてきています。
`

  return (
    <div className="detail">
      <div className="detail__content">
        <h2>{title}</h2>
        <p>{content}</p>
        <div className="detail__content__list">
          <dl>
            <dt>１．子がいない方</dt>
            <dd>
              子がいない方の相続人は直系尊属（両親、祖父母等）、そして直系尊属も亡くなっていれば、兄弟姉妹が相続人となります。
              <br />
              兄弟姉妹相続の場合は、まず相続人を確定させるための戸籍収集が二世代分集めることになります。
              <br />
              そして、兄弟姉妹のいずれかが先死していると、甥や姪が相続人になりますので、配偶者がいれば、その配偶者が、甥・姪と遺産分割の話し合いをしなければなりません。
              <br />
              思いも掛けない親族が相続人になりうる可能性がある場合、どのような財産があり、誰に任せるのか明確にする意味でも、有効な遺言書を作成しておくことで、遺された親族は、争うことなく、スムーズな手続きが実現できます。
            </dd>
          </dl>

          <dl>
            <dt>２．内縁関係にある方</dt>
            <dd>
              現在の民法では、内縁関係の相手には相続権は与えられていないため、遺された相手に生活保障としての財産を遺されたい場合、
              遺留分に注意しながら遺言書を作成することは必須となります。
            </dd>
          </dl>

          <dl>
            <dt>３．思い当たる相続人のいない方</dt>
            <dd>
              相続人がいない場合、最終的に相続財産は国庫に帰属することになります。
              <br />
              もし、お世話になった、福祉団体や、教育団体、寺社等に寄付したいという思いがあれば、
              遺言書を作成し、遺言執行者を指定しておく必要があります。
            </dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default IgonSyurui
