import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import HeroImage from "../components/heroImage"
import SEO from "../components/seo"
import IgonSyurui from "../components/igonDetail/igonSyurui"
import BackContact from "../components/backContact"
import BlogLink from "../components/blogLink"

const Igon = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      igon: file(relativePath: { eq: "igon.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
          original {
            height
            src
            width
          }
        }
      }
    }
  `)

  const description =
    "遺言書の作成について、作成しておくべき場合や、遺言書作成の手続き、新しい自筆証書遺言保管制度などについて大阪の司法書士事務所の司法書士法人みつわ合同事務所がご紹介します。"

  return (
    <Layout>
      <SEO
        description={description}
        title="遺言書の作成　公正証書遺言 | 司法書士　大阪"
        pagepath={location.pathname}
        pageimg={data.igon.childImageSharp.original.src}
        pageimgw={data.igon.childImageSharp.original.width}
        pageimgh={data.igon.childImageSharp.original.height}
      />
      <div className="main__container">
        <HeroImage
          name={"igon"}
          fluid={data.igon.childImageSharp.fluid}
          title={"司法書士法人みつわ合同事務所"}
          subTitle={"遺言"}
        />
        <section className="igon">
          <div className="igon__container">
            <h1 className="igon__title">遺言</h1>
            <IgonSyurui />
            <BlogLink link={"will"} linkName={"遺言"} />
          </div>
        </section>
        <BackContact link={"/consul/"} linkName={"戻る"} />
      </div>
    </Layout>
  )
}

export default Igon
